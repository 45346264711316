import { Header } from 'components/policy';
import { Privacy20211001 } from 'components/policy';

const PolicyPrivacy = () => {
  return (
    <>
      <Header />
      <Privacy20211001 />
    </>
  );
};

export default PolicyPrivacy;
